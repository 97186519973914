@import "_base";

.floating-box {
   position:fixed;
   bottom:20px; left:0;
   width:100%;
   z-index: 11;
   .container {
      display:flex;
      justify-content: flex-end;
      position:relative;
      max-width: 1280px;
      a {
         position:absolute;
         bottom:0; right:15px;
         display:block;
         color:#fff;
         border: 4px solid rgba(95,198,244,0.26);
         border-radius: 28px;
         text-decoration: none;
         animation:flying 3s ease infinite;
         .inner {
            display:block;
            border-radius: 25px;
            background-image: linear-gradient(150deg, #00A0E8 0%, #99DDFF 60%, #00A0E8 100%);
            padding:20px 30px;
            @media (max-width:768px - 1px) { // sm
               padding:10px 20px;
            }
            span {
               font-size:20px;
               @media (max-width:768px - 1px) { // sm
                  font-size:16px;
               }
            }
            .ico-box {
               display:block;
               position:relative;
               width:80px;
               margin:10px auto 0;
               @media (max-width:768px - 1px) { // sm
                  width:50px;
               }
               .ico {
                  position:absolute;
                  top:50%; left:50%;
                  transform:translate(-50%, -50%);
                  padding: 18px;
                  @media (max-width:768px - 1px) { // sm
                     padding:10px;
                  }
               }
            }
         }
      }
   }
   @keyframes flying {
      0%, 100% {
         transform: translateY(0);
        }
   
      50% {
         transform: translateY(-10px);
      }
   }
}
.heading-title {
   display:flex;
   align-items:center;
   background: #ECF8FE;
   border-radius: 16px;
   padding:45px 37px;
   @media (max-width:768px - 1px) { // sm
      margin: 0 -1rem 2rem !important;
      border-radius:0;
      padding:25px 37px;
   }
   h3 {
      margin:0;
   }
   img {
      @media (max-width:768px - 1px) { // sm
         width:25px;
      }
   }
}
.wrapper {
   overflow:hidden;
   .banner-container {
      .container {
         @media (max-width:768px - 1px) { // sm
            padding:0;
         }
      }
      .banner-swiper {
         // height:540px;
         // @media (max-width:992px - 1px) { // md
         //    height:auto;
         // }
         @media (max-width:768px - 1px) { // sm
            height:initial;
         }
         .swiper-wrapper {
            @media (min-width:768px) {
               height:auto !important;
            }
         }
         .swiper-slide {
            position:relative;
            overflow:hidden;
            background: #F3FBFE;
            height:100%;
            pointer-events:none;
            @media (max-width:768px - 1px) { // sm
               height:auto;
            }
            &.swiper-slide-active {
               pointer-events:auto;
               z-index:1;
            }
            .slogan {
               position:absolute;
               top:10px; right:0;
               width: 350px;
               @media (max-width:768px - 1px) { // sm
                  width:120px;
               }
            }
            .content-box {
               position:absolute;
               bottom:10%; left:40px;
               // transform:translate(0, -50%);
               // width:500px;
               height:auto;
               background: rgba(255,255,255,0.70);
               border-radius: 15px;
               padding:20px 30px 10px;
               @media (max-width:768px - 1px) { // sm
                  position:static;
                  transform:none;
                  width:auto;
                  text-align:center;
                  padding:25px;
               }
               h1 {
                  font-size:40px;
                  font-weight:500;
                  color: #00A0E8;
                  margin:0;
                  @media (max-width:992px - 1px) { // md
                     font-size:36px;
                  }
                  @media (max-width:768px - 1px) { // sm
                     font-size:24px;
                  }
               }
               h2 {
                  font-size:30px;
                  color: #00A0E8;
                  @media (max-width:992px - 1px) { // md
                     font-size:24px;
                  }
                  @media (max-width:768px - 1px) { // sm
                     font-size:18px;
                  }
               }
               h3 {
                  color: #00A0E8;
               }
               hr {
                  width:100%; height:3px;
                  border:0;
                  background-image: linear-gradient(-32deg, #00CEF5 0%, #00A0E8 76%);
                  margin-top:0;
                  margin-bottom:10px;
                  @media (max-width:768px - 1px) { // sm
                     width:42px; height:2px;
                  }
               }
               p {
                  font-size:12px;
                  margin:0;
               }
               .reg-btn {
                  display:block;
                  font-size:24px;
                  width:240px;
                  margin:15px 0 20px;
                  @media (max-width:768px - 1px) { // sm
                     font-size:20px;
                     width:210px;
                     margin:20px auto 20px;
                  }
               }
            }
         }
      }
      .banner-anchor-box {
         .banner-anchor-swiper {
            overflow:visible;
            box-shadow: 0 3px 4px 0 rgba(0,0,0,0.10);
            .swiper-slide {
               display:flex;
               align-items: center;
               justify-content: center;
               height:100px;
               cursor:pointer;
               transition:box-shadow .3s ease;
               &:hover {
                  .bg {
                     opacity:0.2;
                  }
               }
               &.swiper-slide-thumb-active {
                  position:relative;
                  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
                  .bg {
                     opacity:1;
                  }
                  .text-box {
                     .ico {
                        .ico-bg {
                           opacity:1;
                        }
                     }
                     p {
                        color:#fff;
                        font-weight:500;
                     }
                  }
               }
               .bg {
                  position:absolute;
                  top:0; left:0;
                  width:100%; height:100%;
                  background-image: linear-gradient(-36deg, #00A0E8 0%, #72D2FA 100%);
                  opacity:0;
                  transition:.3s ease;
               }
               .text-box {
                  display:flex;
                  justify-content:center;
                  align-items:center;
                  position:relative;
                  .ico {
                     position:relative;
                     width:80px;
                     @media (max-width:992px - 1px) { // md
                        width:60px;
                     }
                     .ico-bg {
                        opacity:0;
                        transition:.3s ease;
                     }
                     .img {
                        display:flex;
                        justify-content: center;
                        align-items:center;
                        position:absolute;
                        top:0; left:0;
                        width:100%; height:100%;
                        padding:17px;
                        img {
                           width:100%;
                        }
                     }
                  }
                  p {
                     font-size:18px;
                     margin:0 0 0 10px;
                     transition:.3s ease;
                     @media (max-width:992px - 1px) { // md
                        font-size:14px;
                     }
                  }
               }
            }
         }
      }
   }
   .service-container {
      padding:60px 0 60px 0;
      @media (max-width:768px - 1px) { // sm
         padding:40px 15px;
      }
      .title {
         padding-bottom:40px;
         @media (max-width:768px - 1px) { // sm
            padding-bottom:20px;
         }
         h2 {
            font-size:20px;
            color: #00A0E8;
            @media (max-width:768px - 1px) { // sm
               font-size:20px;
            }
         }
         h1 {
            font-size: 30px;
            font-weight: 700;
            color: #273A42;
            @media (max-width:768px - 1px) { // sm
               font-size:24px;
            }
         }
         hr {
            width:94px; height:2px;
            border:0;
            background-image: linear-gradient(-32deg, #00CEF5 0%, #00A0E8 76%);
            margin-top:0;
            @media (max-width:768px - 1px) { // sm
               width:42px;
            }
         }
      }
      .item-box {
         display:inline-flex;
         flex-wrap: wrap;
         width:100%;
         margin:-14px 0 0 -14px;
         width: calc(100% + 14px);
         .item {
            margin: 14px 0 0 14px;
            width:calc(33.3% - 14px);
            background-image: linear-gradient(150deg, #6CDDF8 0%, #3AB8EE 100%);
            text-decoration: none;
            @media (max-width:992px - 1px) { // md
               width:calc(50% - 14px);
            }
            @media (max-width:768px - 1px) { // sm
               width:100%;
            }
            &:hover {
               .inner-item {
                  .text-box {
                     &:before {
                        opacity:1;
                     }
                     h6 {
                        color:#fff;
                        &:after {
                           opacity:1;
                        }
                     }
                     p {
                        color:#fff;
                     }
                  }
               }
            }
            .inner-item {
               display:flex;
               flex-direction: column;
               align-items:stretch;
               justify-content: stretch;
               height:100%;
               padding:1px;
               .thumb {
                  position:relative;
                  overflow:hidden;
                  margin-bottom:1px;
                  height:0;
                  padding-bottom:60%;
                  .tag {
                     position:absolute;
                     top:20px; left:25px;
                     z-index: 1;
                     font-size:20px;
                     font-weight:bold;
                     color: #00A0E8;
                     &.text-white {
                        text-shadow:0 0 5px rgba(0,0,0,0.3);
                     }
                  }
                  img {
                     position:absolute;
                     top:50%; left:50%;
                     transform:translate(-50%, -50%);
                  }
               }
               .text-box {
                  flex:1 0 auto;
                  position:relative;
                  padding:25px 20px 25px 30px;
                  background-color:#fff;
                  &:before {
                     content:'';
                     position:absolute;
                     top:0; left:0;
                     width:100%; height:100%;
                     background-image: linear-gradient(150deg, #6CDDF8 0%, #3AB8EE 100%);
                     opacity:0;
                     transition:.3s ease;
                  }
                  h6 {
                     position:relative;
                     font-size:20px;
                     font-weight:500;
                     line-height:1.5em;
                     color: #00A0E8;
                     padding:0 0 0 18px;
                     margin-bottom:15px;
                     transition:.3s ease;
                     &:before {
                        content:'';
                        position:absolute;
                        top:0; left:0;
                        width:4px; height:100%;
                        background-image: linear-gradient(180deg, #E5FAFF 0%, #3AB8EE 100%);
                     }
                     &:after {
                        content:'';
                        position:absolute;
                        top:0; left:0;
                        width:4px; height:100%;
                        background-color:#fff;
                        opacity:0;
                        transition:.3s ease;
                     }
                  }
                  p {
                     position:relative;
                     font-size:14px;
                     margin:0;
                     transition:.3s ease;
                  }
               }
            }
         }
      }
   }
   .news-container {
      padding:50px 0 40px;
      margin-bottom:50px;
      background-color:#273A42;
      @media (max-width:768px - 1px) { // sm
         padding:25px 0 15px;
         margin-bottom:40px;
      }
      .container {
         position:relative;
         padding: 0 120px;
         max-width: 1290px;
         @media (max-width:992px - 1px) { // md
            padding:0 60px;
         }
         @media (max-width:768px - 1px) { // sm
            padding:0 50px;
         }
         h1 {
            font-size:30px;
            font-weight:700;
            color:#fff;
            margin:0;
            @media (max-width:768px - 1px) { // sm
               font-size:24px;
               text-align:center;
            }
         }
         hr {
            display:inline-block;
            border:0;
            width:94px; height:2px;
            background-color:#fff;
            margin-left:0;
            @media (max-width:768px - 1px) { // sm
               margin-left:auto;
               width:42px;
            }
         }
         .news-swiper {
            padding:30px 0 40px;
            @media (max-width:768px - 1px) { // sm
               padding:20px 0 40px;
            }
            .swiper-wrapper {
               @media (min-width:768px) {
                  height:auto !important;
               }
               .swiper-slide {
                  position:relative;
                  &:first-child {
                     &:before {
                        content:none;
                     }
                  }
                  &:before {
                     content:'';
                     position:absolute;
                     top:25px; left:-30px;
                     width:1px; height:85px;
                     background-color:#D6D6D6;
                     @media (max-width:1200px - 1px) { // lg
                        left:-20px;
                     }
                     @media (max-width:768px - 1px) { // sm
                        content:none;
                     }
                  }
                  @media (max-width:768px - 1px) { // sm
                     display:flex;
                     align-items:flex-start;
                  }
                  img {
                     width:38px;
                     @media (max-width:768px - 1px) { // sm
                        width:28px;
                     }
                  }
                  .text-box {
                     @media (max-width:768px - 1px) { // sm
                        flex:1;
                        padding-left:10px;
                     }
                     p, a {
                        font-size:14px;
                        color:#fff;
                     }
                  }
               }
            }
            .swiper-pagination {
               display:none;
               width:100%;
               .swiper-pagination-bullet {
                  margin:0 10px;
                  background-color:#fff;
                  opacity:1;
                  &-active {
                     background: #00A0E8;
                  }
               }
            }
         }
         .swiper-button-prev,
         .swiper-button-next {
            width:40px; height:40px;
            opacity:1;
            transform:translate(0, -50%);
            @media (max-width:768px - 1px) { // sm
               width:12px; height:12px;
            }
            &:after {
               content:none;
            }
            img {
               max-height:100%;
            }
         }
         .swiper-button-prev {
            left:50px;
            @media (max-width:992px - 1px) { // md
               left:10px;
            }
            @media (max-width:768px - 1px) { // sm
               left:20px;
               margin-top:0;
            }
         }
         .swiper-button-next {
            right:50px;
            @media (max-width:992px - 1px) { // md
               right:10px;
            }
            @media (max-width:768px - 1px) { // sm
               right:20px;
               margin-top:0;
            }
         }
      }
   }
   .calculator-container {
      padding-bottom:40px;
      @media (max-width:992px - 1px) { // md
         padding:0 15px 30px;
      }
      .inner-calculator-container {
         display:flex;
         justify-content: center;
         align-items:center;
         background:url(../images/calculator_bg_web.png) no-repeat;
         background-size:100% 100%;
         padding:20px 20px 20px 40px;
         border-radius: 30px;
         @media (max-width:1200px - 1px) { // lg
            padding:60px;
         }
         @media (max-width:992px - 1px) { // md
            background-image:url(../images/calculator_bg_mob.png);
            padding:30px 20px 50px;
            flex-direction: column;
         }
         @media (max-width:768px - 1px) { // sm
            border-radius: 15px;
         }
         .btn-box {
            @media (max-width:1200px - 1px) { // lg
            }
            @media (max-width:992px - 1px) { // md
               padding-bottom:30px;
            }
            img {
               width:170px;
               @media (max-width:992px - 1px) { // md
                  width:127px;
               }
            }
            p {
               font-size:32px;
               color:#fff;
               padding-left: 80px;
               margin-bottom:35px;
               @media (max-width:1200px - 1px) { // lg
                  padding-left:60px;
               }
               @media (max-width:992px - 1px) { // md
                  padding:0;
                  margin-bottom:15px;
                  text-align:right;
               }
            }
            a {
               display:flex;
               justify-content: center;
               align-items:center;
               margin:0 auto;
               font-size:16px;
               color:#00A0E8;
               width:210px; height:56px;
               text-decoration: none;
               @media (max-width:992px - 1px) { // md
                  font-size:20px;
               }
               span {
                  background:#fff;
               }
            }
         }
         .inner-calculator-box {
            flex:1;
            margin-left:30px;
            background: #FFFFFF;
            box-shadow: 0 0 10px 0 rgba(15,131,181,0.24);
            border-radius: 15px;
            padding:45px 35px;
            @media (max-width:1200px - 1px) { // lg
               margin-left:50px;
               border-radius: 30px;
            }
            @media (max-width:992px - 1px) { // md
               margin:0;
               padding:30px 15px 15px;
               width:100%;
            }
            .noUi-target {
               height:5px;
               border:0;
               border-radius:20px;
               background-color:#E8E8E8;
               box-shadow: none;
               .noUi-connects {
                  border-radius:20px;
                  .noUi-connect {
                     background-color:#00A0E8;
                  }
               }
               .noUi-handle {
                  box-shadow:none;
                  background-image: linear-gradient(125deg, #00CEF5 10%, #00A0E8 100%);
                  border: 1px solid #FFFFFF;
                  border-radius:100%;
                  width:24px; height:24px;
                  top: -10px; right: -12px;
                  &:before,
                  &:after {
                     content:none;
                  }
               }
            }
            .nav-tabs {
               margin-bottom:20px;
               .nav-item {
                  border:0;
                  flex:initial;
                  margin-bottom:-1px;
                  &.active {
                     border-bottom:3px solid #00A0E8;
                  }
               }
            }
            .tab-content {
               .tab-pane {
                  .content-box {
                     display:flex;
                     @media (max-width:992px - 1px) { // md
                        flex-direction: column;
                     }
                     .calculator {
                        flex:1.5;
                        input {
                           border: 1px solid #CEEAF5;
                           border-radius: 5px;
                           padding:10px 15px;
                        }
                        .input-box {
                           display:flex;
                           justify-content: center;
                           align-items: center;
                           padding-bottom:30px;
                           @media (max-width:992px - 1px) { // md
                              flex-direction: column;
                              align-items: flex-start;
                           }
                           &:first-child {
                              @media (max-width:992px - 1px) { // md
                                 flex-direction: column;
                                 align-items: flex-start;
                              }
                           }
                           p {
                              flex:1.8;
                              display:flex;
                              align-items: center;
                              margin:0;
                              @media (max-width:992px - 1px) { // md
                                 padding-bottom:10px;
                              }
                              img {
                                 width:28px;
                              }
                              span {
                                 padding-left:5px;
                              }
                           }
                           .input {
                              flex:1;
                           }
                        }
                        #dollar,
                        #owner_dollar {
                           margin-bottom:30px;
                        }
                        .noUi-target {
                           margin-left:33px;
                           @media (max-width:992px - 1px) { // md
                              margin:0;
                           }
                        }
                     }
                     .result {
                        flex:1;
                        display:flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items:center;
                        background-image: linear-gradient(223deg, #E2F3FC 0%, #F3FBFE 100%);
                        border-radius: 14px;
                        margin-left:45px;
                        padding:10px 30px;
                        @media (max-width:992px - 1px) { // md
                           margin:30px 0 0 0;
                        }
                        h1 {
                           font-size: 48px;
                           color: #00A0E8;
                           margin:0;
                        }
                        h6 {
                           font-size: 16px;
                           color: #1A1A1A;
                        }
                        .remark {
                           font-size:13px;
                           color: #666666;
                           line-height:1;
                           width:100%;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}